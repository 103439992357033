import $ from 'jquery';

$(document).ready(function () {
  acfFactoringBlocksBlock();
});

function acfFactoringBlocksBlock() {
  // let maxHeight = 0;
  //
  // $('.acf-factoring-works-block__step').each(function (index, element) {
  //   if ($(element).height() > maxHeight) {
  //     maxHeight = $(element).height();
  //   }
  // });
  //
  // $('.acf-factoring-works-block__step').height(maxHeight);
}
