import $ from 'jquery';

$(document).ready(function () {
  acfHeroBlock();
});

function acfHeroBlock() {
  $('.acf-hero-block__right-images').slick({
    cssEase: 'ease',
    fade: true,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0, // Prevent generating extra markup
  });
}
