import $ from 'jquery';

$(document).ready(function () {
  acfTeamBlock();
});

$(window).on('resize', function () {
  acfTeamBlock();
});

function acfTeamBlock() {
  $('.acf-team-block__team-container').each(function (index, element) {
    let maxHeight = 0;
    $(element).find('.acf-team-block__team-person').height('auto');

    if (window.innerWidth > 640) {
      $(element)
        .find('.acf-team-block__team-person')
        .each(function (index, element) {
          if ($(element).height() > maxHeight) {
            maxHeight = $(element).height();
          }
        });

      $(element).find('.acf-team-block__team-person').height(maxHeight);
    }
  });
}
